
import { useUserSessionStore } from '@/store/modules/commons/user-session-store'
import { computed, defineComponent, PropType, ref } from 'vue'
import ReferralsUpdate from '../components/ReferralsUpdate.vue'
import ReferralsDetails from '../components/ReferralsDetails.vue'
import { referral } from '@/models/referrals/interfaces/ReferralDto'
import { Country } from '@/models/countries/classes/Country'

export default defineComponent({
  components: {
    ReferralsUpdate,
    ReferralsDetails
  },
  props: {
    referrals: {
      type: Array as PropType<referral[]>,
      required: true
    },
    totalRecords: {
      type: Number
    },
    recordsLimit: {
      type: Number
    },
    firstRow: {
      type: Number
    },
    order: {
      type: Number
    },
    page: {
      type: Number
    }
  },
  emits: ['updateReferral', 'onPage', 'onSort', 'onChangeRowsPerPage'],
  setup (props, { emit }) {
    const DASH = process.env.DASH
    const urlDash = ref(DASH)
    const referralsData = computed(() => props.referrals)
    const records = computed(() => props.totalRecords)
    const limit = computed(() => props.recordsLimit)
    const rowsPerPage = ref<number>(limit.value as number)
    const first = computed(() => props.firstRow)
    const order = computed(() => props.order)
    const sortOrder = ref<number>(order.value as number)
    const editingRows = ref([])
    const dataRow = ref<referral>()
    const isVisibleUpdate = ref<boolean>(false)
    const isVisibleDetails = ref<boolean>(false)
    const session = useUserSessionStore()
    const pagina = computed(() => props.page)
    console.log('pagina', pagina.value)
    const onRowEdit = (data: referral) => {
      dataRow.value = data
      isVisibleUpdate.value = true
    }
    const saveModal = (newData: any) => {
      emit('updateReferral', { codeId: dataRow.value?.codeId, value: parseInt(newData.points), appliedBy: session.currentUser._id, comments: newData.comments })
    }
    const showUpdateModal = () => {
      isVisibleUpdate.value = !isVisibleUpdate.value
    }
    const showDetailModal = (data: referral) => {
      dataRow.value = data
      isVisibleDetails.value = !isVisibleDetails.value
    }
    const onPage = async (event: any) => {
      if (rowsPerPage.value !== event.rows) {
        rowsPerPage.value = event.rows
        emit('onChangeRowsPerPage', event)
      } else {
        emit('onPage', event)
      }
    }
    const onSort = async (event: any) => {
      sortOrder.value = -sortOrder.value
      emit('onSort', sortOrder.value)
    }
    return {
      records,
      rowsPerPage,
      referralsData,
      editingRows,
      dataRow,
      isVisibleUpdate,
      isVisibleDetails,
      first,
      urlDash,
      Country,
      onRowEdit,
      showUpdateModal,
      showDetailModal,
      saveModal,
      onPage,
      onSort,
      pagina
    }
  }
})
