import { onMounted, ref, Ref, watch } from 'vue'
import { ReferralsAPI } from '@/api/referrals.api'
import { useToast } from 'primevue/usetoast'
import { ToastLife, ToastSeverities, ToastSummaries } from '@/models/components/toast/enums'
import { Observable } from 'rxjs'
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators'
import { dataReferral, updateReferral } from '@/models/referrals/interfaces/ReferralDto'
import { DataTablePageEvent, DataTableSortEvent } from 'primevue/datatable'
import { SearchType } from '@/models/referrals/enums'

interface ReferralsListState {
  loading: Ref<boolean>
  totalRecords: Ref<number>
  searchTxt: Ref<string>
  searchType: Ref<number>
  page: Ref<number>
  order: Ref<number>
  recordsLimit: Ref<number>
  firstRegister: Ref<number>
  referrals: Ref<dataReferral | undefined>
  updateSearchTxt: (val: string) => void
  updateReferral: (dto: any) => void
  onChangeRowsPerPage: (e: DataTablePageEvent) => void
  onPage: (e: DataTablePageEvent) => void
  onSort: (sortOrder: number) => void
  onChangeSearchType: (op: number) => void
  }

export const useReferralsList = (): ReferralsListState => {
  const toast = useToast()
  const searchTxt = ref('')
  const searchType = ref(SearchType.byCode)
  const page = ref(0)
  const order = ref(1)
  const loading = ref<boolean>(false)
  const totalRecords = ref<number>(0)
  const recordsLimit = ref<number>(10)
  const firstRegister = ref<number>(0)
  const referrals = ref<dataReferral | undefined>()

  const updateSearchTxt = (val: string): void => {
    searchTxt.value = val
  }
  const searchTxt$ = new Observable<string>(subscriber => {
    watch(searchTxt, () => subscriber.next(searchTxt.value))
  })
  searchTxt$.pipe(
    debounceTime(1500),
    distinctUntilChanged(),
    tap(() => (page.value = 0))
  ).subscribe(() => {
    page.value = 0
    updateList()
  })

  const updateList = async () => {
    try {
      loading.value = true
      referrals.value = await ReferralsAPI.findAll(page.value, recordsLimit.value, searchTxt.value, order.value, searchType.value)
      totalRecords.value = referrals.value.totalRecords
      firstRegister.value = recordsLimit.value * page.value
    } catch (error) {
      toast.add({
        summary: ToastSummaries.Error,
        detail: 'Ocurrió un error al cargar el programa de referidos',
        severity: ToastSeverities.Error,
        life: ToastLife.Default
      })
      console.error(error)
    } finally {
      loading.value = false
    }
  }

  const updateReferral = async (dto: updateReferral) => {
    try {
      loading.value = true
      await ReferralsAPI.updateReferral(dto).then(() => {
        toast.add({ severity: 'success', detail: 'Datos actualizados correctamente.', life: 3500 })
        updateList()
      })
    } catch (error) {
      toast.add({
        summary: ToastSummaries.Error,
        detail: 'Ocurrió un error al guardar el programa de referidos',
        severity: ToastSeverities.Error,
        life: ToastLife.Default
      })
      console.error(error)
    } finally {
      loading.value = false
    }
  }

  onMounted(async () => {
    updateList()
  })

  return {
    loading,
    totalRecords,
    searchTxt,
    searchType,
    page,
    order,
    recordsLimit,
    firstRegister,
    referrals,
    updateSearchTxt,
    updateReferral,
    onChangeRowsPerPage: (e: DataTablePageEvent) => {
      recordsLimit.value = e.rows
      updateList()
    },
    onPage: (e: DataTablePageEvent) => {
      page.value = e.page
      updateList()
    },
    onSort: (sortOrder: number) => {
      order.value = sortOrder ?? 1
      updateList()
    },
    onChangeSearchType: (op: number) => {
      searchType.value = op ?? SearchType.byCode
      if (searchTxt.value !== '') {
        updateList()
      }
    }
  }
}
