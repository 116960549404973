
import { defineComponent, ref, watch } from 'vue'
import ReferralsTable from './components/ReferralsTable.vue'
import ModuleHeader from '@/components/commons/boxes/ModuleHeader.vue'
import BrainSpinner from '@/components/commons/BrainSpinner.vue'
import { useReferralsList } from './components/hook/useReferralsList'
import { referral } from '@/models/referrals/interfaces/ReferralDto'
import { SearchType } from '@/models/referrals/enums'

export default defineComponent({
  components: {
    BrainSpinner,
    ReferralsTable,
    ModuleHeader
  },
  props: {},
  setup () {
    const {
      loading,
      searchTxt,
      referrals,
      totalRecords,
      recordsLimit,
      firstRegister,
      order,
      updateSearchTxt,
      updateReferral,
      onChangeRowsPerPage,
      onPage,
      onSort,
      page,
      searchType,
      onChangeSearchType
    } = useReferralsList()
    watch(searchTxt, () => {
      updateSearchTxt(searchTxt.value)
    })
    const dataReferral = ref<referral[] | undefined>()
    watch(referrals, () => {
      dataReferral.value = referrals.value?.data
    })
    const searchOptions = [
      { label: 'Buscar por nombre', value: SearchType.byName },
      { label: 'Buscar por cupón', value: SearchType.byCode }
    ]
    watch(searchType, () => {
      onChangeSearchType(searchType.value)
    })
    return {
      loading,
      dataReferral,
      searchTxt,
      totalRecords,
      recordsLimit,
      firstRegister,
      order,
      updateReferral,
      onChangeRowsPerPage,
      onPage,
      onSort,
      page,
      searchType,
      searchOptions
    }
  }
})
