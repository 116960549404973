
import { computed, defineComponent, ref, watch } from 'vue'
import moment from 'moment'
import { ReferralsAPI } from '@/api/referrals.api'
import { referral, resDetailedPoints } from '@/models/referrals/interfaces/ReferralDto'

export default defineComponent({
  props: {
    referral: {
      type: Object
    },
    visible: {
      type: Boolean
    }
  },
  emits: ['onCloseDetail'],
  setup (props, { emit }) {
    const loading = ref<boolean>(false)
    const visibleProp = computed(() => props.visible)
    const isVisible = ref<boolean>(visibleProp.value)
    const dataReferral = computed(() => props.referral)
    const details = ref<Array<resDetailedPoints>>([])
    watch(visibleProp, () => { isVisible.value = visibleProp.value })

    const endDetail = () => {
      emit('onCloseDetail', false)
    }

    watch(dataReferral, async () => { await getDetail() })

    const getDetail = async () => {
      try {
        loading.value = true
        details.value = await ReferralsAPI.getDetailedPoints(dataReferral.value?.userId)
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    return {
      details,
      isVisible,
      moment,
      loading,
      dataReferral,
      endDetail
    }
  }
})
