import {
  dataReferral,
  resDetailedPoints,
  resUpdateReferral,
  updateReferral
} from '@/models/referrals/interfaces/ReferralDto'
import { Api } from './api'

class ReferralsApi {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.BACKOFFICE_BFF
  }

  public async findAll(
    page: number,
    limit: number,
    s: string,
    order: number,
    searchType: number
  ): Promise<dataReferral> {
    const response = await Api.get<dataReferral>(
      `${this.baseURL}/v1/referrals/all?page=${page}&limit=${limit}&sortBy=cupon&order=${order}&searchType=${searchType}${s.trim() !== '' ? '&s=' + s : ''}`
    )
    return response
  }

  public async updateReferral(dto: updateReferral): Promise<resUpdateReferral> {
    const response = await Api.put<resUpdateReferral>(
      `${this.baseURL}/v1/referrals/points-amount`,
      dto
    )
    return response
  }

  public async getDetailedPoints(userId: string): Promise<resDetailedPoints[]> {
    const response = await Api.get<resDetailedPoints[]>(
      `${this.baseURL}/v1/referrals/details/${userId}`
    )
    return response
  }
}

export const ReferralsAPI = new ReferralsApi()
