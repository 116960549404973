
import { computed, defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    referral: {
      type: Object,
      default: null
    },
    visible: {
      type: Boolean
    }
  },
  emits: ['onClose', 'onSave'],
  setup (props, { emit }) {
    const points = ref<number>(0)
    const comments = ref<string>('')
    const dataReferral = computed(() => props.referral)
    const visibleProp = computed(() => props.visible)
    const isVisibleUpdate = ref<boolean>(visibleProp.value)

    watch(visibleProp, () => { isVisibleUpdate.value = visibleProp.value })

    const endUpdate = () => {
      points.value = 0
      comments.value = ''
      emit('onClose', false)
    }

    const saveUpdate = () => {
      emit('onSave', { points: points.value, comments: comments.value })
    }

    return {
      points,
      comments,
      isVisibleUpdate,
      dataReferral,
      endUpdate,
      saveUpdate
    }
  }
})

